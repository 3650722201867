import React from "react";
import {Avatar, Box, Button, Stack} from "@mui/material";

import axios from "axios";
import {useParams} from "react-router-dom";

import clap from '../assets/images/clap.png'
import laugh from '../assets/images/laugh.png'
import {emojisplosion} from "emojisplosion";
import {API_URL} from "../config";
import pleshDark from "../theme/pleshDark";


export default function ReactionsLive({url, idPlaying, eventPlaying, user}) {
    const {id} = useParams()

    async function postDataApplausi(e, type, emoji) {
        emojisplosion({
            emojiCount:5,
            emojis: [emoji],
            position: () => ({
                x: e.clientX,
                y: e.clientY,
            }),
        })
        await axios.post(`${API_URL}/${id}/reactions?type=${type}`, {user: user.uid})
            .then(r => {
                navigator.vibrate(200)
            })
            .catch(error => console.log(error))
    }

    const ButtonItem = ({type, img, emoji}) => (
        <Avatar id='clapButton' disabled={!eventPlaying} onClick={(e) => postDataApplausi(e, type, emoji)}
                variant={'clap'}
                sx={{bgcolor: pleshDark.palette.primary.main, width: 128, height: 128, cursor: 'pointer', boxShadow: '6px 5px 6px 0px #37373761'}}
        >
            <img style={{height:'5.625rem'}} src={img} />
        </Avatar>
    )

    return (
        <Stack alignItems={'center'}>
            <Stack direction={'row-reverse'} sx={{position:'absolute', bottom:'0', my:4}} display={'flex'} spacing={4}>
                {
                    reactions.map((reaction) => (
                        <ButtonItem key={reaction.id} type={reaction.id} img={reaction.img} emoji={reaction.emoji}/>
                    ))
                }
            </Stack>
        </Stack>
    )
}

const reactions = [
    {
        id: 'applausi',
        emoji: '👏',
        img: clap
    },
    {
        id: 'laugh',
        emoji: '🤣',
        img: laugh
    }
]