import React, {useEffect, useState} from "react";
import {auth, firebase, firestore} from "../firebase/clientApp";
import VoteContent from "../components/VoteContent";
import {useParams} from "react-router-dom";
import {Box, Container} from "@mui/material";
import {useDocument} from "react-firebase-hooks/firestore";
import axios from "axios";
import {API_URL} from "../config";
import {Form} from "../components/Form";

export default function Vote() {
    const {id} = useParams()
    const [user, setUser] = useState(null);
    const [event, eventLoading, eventError] = useDocument(firestore.doc('users/plesh/events/' + id))
    const [displayName, setDisplayName] = useState('')

    const [loading, setLoading] = useState(true);

    async function loginUser() {
        await auth.signInAnonymously().then(() => {}).catch(alert);
    }

    useEffect(async () => {
        setLoading(true)
        await loginUser()
        firebase.auth().onAuthStateChanged(async (user) => {
            setUser(user);
            await axios.post(`${API_URL}/events/${id}/users`,{user: user.uid})
                .then(res =>
                    setDisplayName(res.data.displayName ?? '')
                )
                .catch()
        })
        setLoading(false)
    }, [])

    if (loading) return <></>

    return (
        <Box>
            {(event && !eventLoading && !eventError) &&
                <Container maxWidth={'sm'} className={'vote'} style={{maxHeight: '100%'}}>
                    <Form/>
                    <VoteContent user={user} event={event} loading={loading} displayName={displayName} setDisplayName={setDisplayName}/>
                </Container>
            }
        </Box>
    )
}
